<template>
  <div style="margin: 20px" class="smsPromotion">
    <div class="search-form">
      <span class="search-label">小程序页面名称：</span>
      <el-input v-model="keyWord" class="input" placeholder="请输入"></el-input>

      <el-button class="button" type="primary" icon="el-icon-search" @click="getPageList">查询</el-button>
      <el-button class="button" icon="el-icon-refresh-left" @click="resetSearch">重置</el-button>
    </div>

    <div style="margin-top: 20px">
      <div style="margin: 10px 20px">
        <el-button icon="el-icon-plus" type="primary" @click="add">新增短信网址</el-button>
      </div>

      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border :header-cell-style="{ 'background-color': '#f5f7fa' }">
        <el-table-column label="序号" prop="id" width="80px"> </el-table-column>
        <el-table-column prop="pageName" label="小程序页面名称"> </el-table-column>
        <el-table-column prop="pageUrl" label="小程序页面地址"> </el-table-column>
        <el-table-column prop="" label="参数" width="80px">
          <template slot-scope="scope">
            <div style="text-align: center">
              {{ scope.row.paramId ? scope.row.paramId : '/' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="messageUrl" label="短信URL Link"> </el-table-column>
        <el-table-column prop="createUserName" label="创建人"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column prop="prop" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button slot="reference" type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="footer-row">
        <div class="el-pagination">
          <el-pagination
            background
            layout="->,total,prev, pager, next,sizes"
            :total="total"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="10"
            @size-change="pageSizeChange"
            @current-change="pageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <div class="dialog">
      <el-dialog :title="isAdd ? '新增短信地址' : '编辑短信地址'" :visible.sync="dialogVisible" width="700px">
        <AddSmsLink v-if="dialogVisible == true" ref="addDialog" :detail-form="detailForm" :is-add="isAdd" @refreshDetailForm="refreshDetailForm"></AddSmsLink>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import AddSmsLink from '@/views/smsPromotion/addSmsLink'

import { messageLinkAddOrEdit, getMessageLinkPageList, deleteMessage, messageLinkDetail } from '@/api/index'

export default {
  components: {
    AddSmsLink,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      keyWord: '',
      dialogVisible: false,
      detailForm: {},
      isAdd: true,
      currentId: '',
      isEdit: false,
    }
  },
  mounted() {
    this.getPageList()
  },
  methods: {
    add() {
      this.dialogVisible = true
      this.detailForm = {}
    },
    pageSizeChange(val) {
      this.pageSize = val
      this.getPageList()
    },
    pageChange(val) {
      this.pageNumber = val
      this.getPageList()
    },
    resetSearch() {
      this.keyWord = ''
      this.getPageList()
    },
    getPageList() {
      const reqObj = {
        data: {
          pageName: this.keyWord,
        },
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
      getMessageLinkPageList(reqObj)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data.content
            this.total = parseFloat(res.data.total)
          }
        })
        .catch((err) => console.log(err))
    },
    handleDelete(row) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const reqObj = {
            data: row.id,
          }
          deleteMessage(reqObj)
            .then((res) => {
              if (res.success) {
                this.getPageList()
                this.$message({
                  message: '删除成功',
                  type: 'success',
                })
              }
            })
            .catch((err) => console.log(err))
        })
        .catch(() => {})
    },
    submit() {
      const addDialog = this.$refs.addDialog
      let reqObj = {}

      if (this.isAdd) {
        reqObj = {
          data: {
            pageName: addDialog.formData.pageName,
            pageUrl: addDialog.formData.pageUrl,
            paramId: addDialog.formData.id,
            messageUrl: addDialog.formData.messageUrl,
            remark: addDialog.formData.remark,
          },
        }
      }

      if (!this.isAdd) {
        reqObj = {
          data: {
            pageName: addDialog.formData.pageName,
            pageUrl: addDialog.formData.pageUrl,
            paramId: addDialog.formData.id,
            messageUrl: addDialog.formData.messageUrl,
            remark: addDialog.formData.remark,
            id: this.currentId,
          },
        }
      }

      addDialog.$refs.addSmsLinkForm.validate((valid) => {
        if (!valid) return

        messageLinkAddOrEdit(reqObj)
          .then((res) => {
            if (res.success) {
              this.getPageList()
              this.dialogVisible = false
            }

            const text = this.isAdd ? '添加' : '修改'

            this.$message({
              message: `${text}成功`,
              type: 'success',
            })

            this.isAdd = true
          })
          .catch((err) => console.log(err))
      })
    },
    edit(row) {
      this.currentId = row.id
      this.isAdd = false
      const reqObj = {
        data: row.id,
      }
      messageLinkDetail(reqObj)
        .then((res) => {
          if (res.success) {
            console.log(res.data)
            const detailForm1 = {
              pageName: res.data.pageName,
              pageUrl: res.data.pageUrl,
              messageUrl: res.data.messageUrl,
              remark: res.data.remark,
              paramId: res.data.paramId,
            }

            this.detailForm = detailForm1

            console.log(this.detailForm.paramId)

            this.dialogVisible = true
          }
        })
        .catch()
    },
    refreshDetailForm() {
      this.detailForm = {}
      this.isAdd = true
    },
  },
}
</script>

<style lang="less" scoped>
.smsPromotion {
  background-color: #fff;
  padding: 0 20px 20px 20px;

  .search-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .search-label {
      font-size: 14px;
      margin-left: 20px;
    }

    .input {
      width: 200px;
    }

    .button {
      margin-left: 20px;
    }
  }

  .confirmButton {
    margin-left: 10px;
  }

  .footer-row {
    display: flex;
    margin-top: 20px;

    .el-pagination {
      width: 100%;
    }
  }
}
</style>
