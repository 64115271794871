<template>
  <div class="addSmsLink">
    <div class="dialogForm">
      <!-- <el-form
        :model="formData"
        inline
        label-position="right"
        label-width="130px"
        size="small"
        :rules="rules"
        ref="addSmsLinkForm"
      >
        <el-form-item prop="pageName" label="小程序页面名称:">
          <el-input v-model="formData.pageName" class="input"></el-input>
        </el-form-item>
        <el-form-item prop="pageUrl" label="小程序页面地址:">
          <el-row :gutter="20">
            <el-col :span="16">
              <el-select v-model="formData.pageUrl" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.dictionaryId"
                  :label="item.dictionaryName"
                  :value="item.dictionaryValue"
                >
                </el-option> </el-select
            ></el-col>
            <el-col :span="8"
              ><el-button type="primary" @click="handleSmsLink"
                >生成短信链接</el-button
              ></el-col
            >
          </el-row>
        </el-form-item>
        <el-form-item prop="messageUrl" label="短信URL Link:">
          <el-row :gutter="20">
            <el-col :span="16"
              ><el-input disabled v-model="formData.messageUrl"></el-input
            ></el-col>
            <el-col :span="8"
              ><el-button type="primary" @click="doCopy"
                >复制</el-button
              ></el-col
            >
          </el-row>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            type="textarea"
            class="input"
            v-model="formData.remark"
          ></el-input>
        </el-form-item>
      </el-form> -->
      <el-form ref="addSmsLinkForm" :rules="rules" :model="formData" label-width="130px" size="small">
        <el-form-item prop="pageName" label="小程序页面名称:">
          <el-input v-model="formData.pageName" placeholder="请输入小程序页面名称" class="input"></el-input>
        </el-form-item>
        <el-form-item prop="pageUrl" label="小程序页面地址:">
          <el-select v-model="formData.pageUrl" class="input" placeholder="请选择" :disabled="!isAdd" @change="selectChange">
            <el-option v-for="item in options" :key="item.dictionaryId" :label="item.dictionaryName + '—' + item.dictionaryValue" :value="item.dictionaryValue"> </el-option>
          </el-select>
          <el-button v-if="isAdd" class="button" type="primary" @click="handleSmsLink">生成短信链接</el-button>
        </el-form-item>
        <el-form-item v-if="idVisible" prop="id" label="参数">
          <el-tooltip class="item" effect="dark" content="产品详情页请输入产品的productid，如果是企业影像馆详情页请输入企业ID" placement="bottom">
            <el-input v-model="formData.id" placeholder="请输入参数" class="input" :disabled="!isAdd"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="messageUrl" label="短信URL Link:">
          <el-input v-model="formData.messageUrl" class="input" disabled></el-input>
          <el-button class="button" type="primary" @click="doCopy">复制</el-button>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="formData.remark" placeholder="请输入" type="textarea" class="input"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getSmsLink, getMiniAppLink } from '@/api/index'
export default {
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const checkId = (rule, value, callback) => {
      if (this.formData.pageUrl === 'pages/product-detail/product-detail' || this.formData.pageUrl === 'pages/company-photo-desc/company-photo-desc') {
        if (isNaN(value)) {
          callback(new Error('请输入数字'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      formData: {
        pageName: '',
        pageUrl: '',
        id: '',
        messageUrl: '',
        remark: '',
      },
      rules: {
        pageName: [
          { required: true, message: '请输入小程序页面名称', trigger: 'blur' },
          { max: 50, message: '最多输入50个字符', trigger: 'blur' },
        ],
        pageUrl: [{ required: true, message: '请输入小程序页面地址', trigger: 'blur' }],
        id: [
          { validator: checkId, trigger: 'blur' },
          { required: true, message: '请输入参数', trigger: 'blur' },
        ],
        messageUrl: [{ required: true, message: '请输入短信URL Link', trigger: 'blur' }],
      },
      options: [],
      value: '',
      currentPage: '',
      idVisible: false,
    }
  },
  mounted() {
    this.isParamId()
    this.transmitData()
    this.getAppLink()
  },
  beforeDestroy() {
    Object.assign(this._data, this.$options.data())
    this.$emit('refreshDetailForm')
  },
  methods: {
    handleSmsLink() {
      if (this.formData.pageUrl === 'pages/product-detail/product-detail' || this.formData.pageUrl === 'pages/company-photo-desc/company-photo-desc') {
        if (this.formData.id === '') {
          this.$message({
            message: '请输入参数',
            type: 'warning',
          })
          return
        }
      }

      const reqObj = {
        data: {
          path: this.formData.pageUrl,
          id: this.formData.id,
        },
      }
      getSmsLink(reqObj)
        .then((res) => {
          if (res.success) {
            this.formData.messageUrl = res.data
            return
          }
          this.$message.error(res.description)
        })
        .catch((err) => console.log(err))
    },
    doCopy() {
      this.$copyText(this.formData.messageUrl)
        .then((message) => {
          this.$message.success('复制成功')
        })
        .catch(() => {
          this.$message.error('复制失败')
        })
    },
    transmitData() {
      if (this.detailForm.pageName) {
        if (this.detailForm.paramId) {
          const value = this.detailForm.paramId

          this.formData = {
            ...JSON.parse(JSON.stringify(this.detailForm)),
            id: '',
          }
          delete this.formData.paramId
          this.formData.id = value
          this.$forceUpdate()
          return
        }
        this.formData = this.detailForm
      }
    },
    getAppLink() {
      const reqObj = {
        data: {
          key: 'mini_app_pro_link',
        },
      }
      getMiniAppLink(reqObj)
        .then((res) => {
          this.options = res.data.childs
        })
        .catch((err) => console.log(err))
    },
    selectChange() {
      if (this.formData.pageUrl === 'pages/product-detail/product-detail' || this.formData.pageUrl === 'pages/company-photo-desc/company-photo-desc') {
        this.idVisible = true
      } else {
        this.idVisible = false
      }
    },
    isParamId() {
      if (this.detailForm.paramId) {
        this.idVisible = true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.addSmsLink {
  .input {
    width: 300px;
  }

  .dialogForm {
    margin-left: 50px;
  }

  .button {
    margin-left: 20px;
    width: 100px;
  }
}
</style>
